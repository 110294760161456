// eslint-disable-next-line no-restricted-imports
import { Recurrence, SnoozeOption, TaskInstanceStatus, TaskStatus } from "../reclaim-api/client";

const SNOOZE_INTERVALS = [
  SnoozeOption.FROM_NOW_15M,
  SnoozeOption.FROM_NOW_30M,
  SnoozeOption.FROM_NOW_1H,
  SnoozeOption.FROM_NOW_2H,
  SnoozeOption.FROM_NOW_4H,
  SnoozeOption.TOMORROW,
  SnoozeOption.IN_TWO_DAYS,
  SnoozeOption.NEXT_WEEK,
] as const;

export const SNOOZE_LABELS: Record<SnoozeOption, string> = {
  [SnoozeOption.FROM_NOW_15M]: "15min",
  [SnoozeOption.FROM_NOW_30M]: "30min",
  [SnoozeOption.FROM_NOW_1H]: "1hr",
  [SnoozeOption.FROM_NOW_2H]: "2hrs",
  [SnoozeOption.FROM_NOW_4H]: "4hrs",
  [SnoozeOption.TOMORROW]: "1 day",
  [SnoozeOption.IN_TWO_DAYS]: "2 days",
  [SnoozeOption.NEXT_WEEK]: "1 week",
};

export type OneOnOneSkipOption = "ANOTHER_DAY" | "ANOTHER_WEEK" | "ANOTHER_TIME";

function getStartOfDay(dt: Date): Date {
  const dayStart = new Date(dt);
  dayStart.setUTCHours(0, 0, 0, 0);
  return dayStart;
}

function taskInstanceIsUpcoming(taskInstanceStatus: TaskInstanceStatus): boolean {
  return taskInstanceStatus === TaskInstanceStatus.ACTIVE || taskInstanceStatus === TaskInstanceStatus.PENDING;
}

export function getSnoozeScentenceEnder(snoozeOption?: SnoozeOption): string {
  switch (snoozeOption) {
    case SnoozeOption.FROM_NOW_15M:
      return " to 15 minutes from now";
    case SnoozeOption.FROM_NOW_30M:
      return " to 30 minutes from now";
    case SnoozeOption.FROM_NOW_1H:
      return " to 1 hour from now";
    case SnoozeOption.FROM_NOW_2H:
      return " to 2 hours from now";
    case SnoozeOption.FROM_NOW_4H:
      return " to 4 hours from now";
    case SnoozeOption.TOMORROW:
      return " until tomorrow";
    case SnoozeOption.IN_TWO_DAYS:
      return " to 2 days from";
    case SnoozeOption.NEXT_WEEK:
      return " until next week";
    default:
      return "";
  }
}

export const ACTION_VISIBILITY_LOGIC = {
  Habits: {
    assignment: {
      start(habitId: number, currentAssignmentId: number | undefined): boolean {
        return !ACTION_VISIBILITY_LOGIC.Habits.assignment.stop(habitId, currentAssignmentId);
      },

      restart(habitId: number, currentAssignmentId: number | undefined): boolean {
        return ACTION_VISIBILITY_LOGIC.Habits.assignment.stop(habitId, currentAssignmentId);
      },

      stop(habitId: number, currentAssignmentId: number | undefined): boolean {
        return habitId === currentAssignmentId;
      },

      edit(): boolean {
        return true;
      },

      reset(exceptions: unknown[] | undefined): boolean {
        return !!exceptions?.length;
      },
    },
    instances: {
      lockOrUnlock(eventStart: Date, now: Date): boolean {
        return eventStart > now;
      },

      skip(): boolean {
        return true;
      },

      // TODO: this really needs to check if that habit
      // is daily too, but recurrence is not easily
      // accessible from where this function is used
      reschedule(eventStart: Date, now: Date): boolean {
        const nowDayStart = getStartOfDay(now);
        return eventStart >= nowDayStart;
      },

      rescheduleType(eventStart: Date, recurrence: Recurrence, now: Date): "quick" | "snoozeOptions" | "none" {
        const nowDayStart = getStartOfDay(now);
        if (eventStart >= nowDayStart) return "snoozeOptions";
        else return recurrence === Recurrence.Daily ? "none" : "quick";
      },

      snoozeOptions(recurrence: Recurrence): readonly SnoozeOption[] {
        switch (recurrence) {
          case Recurrence.Daily:
            return [
              SnoozeOption.FROM_NOW_15M,
              SnoozeOption.FROM_NOW_30M,
              SnoozeOption.FROM_NOW_1H,
              SnoozeOption.FROM_NOW_2H,
              SnoozeOption.FROM_NOW_4H,
            ];
          case Recurrence.Weekly:
            return [
              SnoozeOption.FROM_NOW_15M,
              SnoozeOption.FROM_NOW_30M,
              SnoozeOption.FROM_NOW_1H,
              SnoozeOption.FROM_NOW_2H,
              SnoozeOption.FROM_NOW_4H,
              SnoozeOption.TOMORROW,
            ];
          case Recurrence.Biweekly:
            return [
              SnoozeOption.FROM_NOW_15M,
              SnoozeOption.FROM_NOW_30M,
              SnoozeOption.FROM_NOW_1H,
              SnoozeOption.FROM_NOW_2H,
              SnoozeOption.FROM_NOW_4H,
              SnoozeOption.TOMORROW,
              SnoozeOption.IN_TWO_DAYS,
            ];
          case Recurrence.Monthly:
          case Recurrence.Quarterly:
            return [
              SnoozeOption.FROM_NOW_15M,
              SnoozeOption.FROM_NOW_30M,
              SnoozeOption.FROM_NOW_1H,
              SnoozeOption.FROM_NOW_2H,
              SnoozeOption.FROM_NOW_4H,
              SnoozeOption.TOMORROW,
              SnoozeOption.IN_TWO_DAYS,
              SnoozeOption.NEXT_WEEK,
            ];
        }
      },

      markDone(
        habitId: number,
        eventStart: Date,
        eventEnd: Date,
        now: Date,
        currentAssignmentId: number | undefined
      ): boolean {
        // Happening now can mark done.
        if (habitId === currentAssignmentId) return true;

        const eventDayStart = getStartOfDay(eventStart);
        const nowDayStart = getStartOfDay(now);

        return eventDayStart === nowDayStart && eventEnd > now;
      },
    },
  },
  Tasks: {
    assignment: {
      start(taskId: number, currentAssignmentId: number | undefined): boolean {
        // alternative: task.getInstances().stream().map(TaskInstance::getStatus).anyMatch(status -> status.equals(TaskInstanceStatus.ACTIVE));
        return !ACTION_VISIBILITY_LOGIC.Tasks.assignment.stop(taskId, currentAssignmentId);
      },

      restart(taskId: number, currentAssignmentId: number | undefined): boolean {
        return ACTION_VISIBILITY_LOGIC.Tasks.assignment.stop(taskId, currentAssignmentId);
      },

      stop(taskId: number, currentAssignmentId: number | undefined): boolean {
        return taskId === currentAssignmentId;
      },

      edit(): boolean {
        return true;
      },

      markDone(taskStatus: TaskStatus): boolean {
        return taskStatus !== TaskStatus.COMPLETE;
      },

      markIncomplete(taskStatus: TaskStatus): boolean {
        return taskStatus === TaskStatus.COMPLETE;
      },

      logWork(): boolean {
        return true;
      },

      addTime(): boolean {
        return true;
      },

      reset(exceptions: unknown[] | undefined): boolean {
        return !!exceptions?.length;
      },

      moveToTop(taskIndex: number, minIndex: number): boolean {
        return typeof taskIndex !== "number" || taskIndex !== minIndex;
      },

      delete(): boolean {
        return true;
      },
    },
    instances: {
      lockOrUnlock(taskInstanceStatus: TaskInstanceStatus): boolean {
        return taskInstanceIsUpcoming(taskInstanceStatus);
      },

      rescheduleType(eventEnd: Date, now: Date): "quick" | "snoozeOptions" {
        return eventEnd >= now ? "snoozeOptions" : "quick";
      },

      rescheduleWithSnooze(taskInstanceEnd: Date, now: Date): boolean {
        return taskInstanceEnd > now;
      },

      snoozeOptions(): readonly SnoozeOption[] {
        return SNOOZE_INTERVALS;
      },
    },
  },
  OneOnOnes: {
    assignment: {
      reset(exceptions: unknown[] | undefined): boolean {
        return !!exceptions?.length;
      },
    },
    instances: {
      lockOrUnlock(eventStart: Date, now: Date): boolean {
        return eventStart > now;
      },

      reschedule(): boolean {
        return true;
      },

      skipOptions(): readonly OneOnOneSkipOption[] {
        return ["ANOTHER_TIME", "ANOTHER_DAY", "ANOTHER_WEEK"];
      },
    },
  },
};
